import React from 'react'
import { IconsProps } from 'msp-integrations'

const WebIcon = (props: IconsProps) => {
  return (
    <svg
      {...props}
      version='1.1'
      id='Ebene_1'
      x='0px'
      y='0px'
      viewBox='0 0 24 24'
    >
      <path d="M12 2.5c-5.24 0-9.5 4.26-9.5 9.5s4.26 9.5 9.5 9.5 9.5-4.26 9.5-9.5-4.26-9.5-9.5-9.5zm0 18c-1.42 0-2.68-1.94-3.31-4.69h6.62c-.63 2.75-1.89 4.69-3.31 4.69zm-3.5-5.69a17.986 17.986 0 0 1 0-5.62h7a18.084 18.084 0 0 1 0 5.62h-7zM12 3.5c1.42 0 2.68 1.94 3.31 4.69H8.69C9.32 5.44 10.58 3.5 12 3.5zm4.52 5.69h3.49c.31.88.49 1.82.49 2.81s-.18 1.93-.49 2.81h-3.49c.13-.89.2-1.83.2-2.81s-.07-1.93-.2-2.81zm3.07-1h-3.25c-.39-1.82-1.04-3.32-1.87-4.32 2.23.68 4.08 2.26 5.12 4.32zM9.54 3.87c-.83 1-1.49 2.5-1.88 4.32H4.41a8.542 8.542 0 0 1 5.13-4.32zM3.99 9.19h3.49c-.13.89-.2 1.83-.2 2.81s.07 1.93.2 2.81H3.99c-.31-.88-.49-1.82-.49-2.81s.18-1.93.49-2.81zm.42 6.62h3.25c.39 1.82 1.04 3.32 1.87 4.32a8.551 8.551 0 0 1-5.12-4.32zm10.05 4.32c.83-1.01 1.49-2.51 1.87-4.32h3.25a8.496 8.496 0 0 1-5.12 4.32z"/>
    </svg>
  )
}

export default WebIcon
